import { FC } from 'react';
import { useCard, Props } from './useCard';
import styles from './Card.module.css';

export const Card: FC<Props> = (props) => {
  const { children, title, photo } = useCard(props);

  return (
    <div className={styles.card}>
      {photo && <img src={photo} className={styles.photo} />}
      <div className={styles.contents}>
        {title && <div className={styles.title}>{title}</div>}
        {children}
      </div>
    </div>);

};