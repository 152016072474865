import { ReactNode } from 'react'

export interface Props {
  title?: string
  photo?: string
  children: ReactNode
}

export const useCard = (props: Props) => {
  const { title, photo, children } = props

  return {
    title,
    photo,
    children,
  }
}
